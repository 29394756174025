import React, { Component } from 'react';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { updateEventSuccess as updateEventSuccessAction } from '../../redux/eventsRedux';
import Switch from '../../common/Switch';
import Button from '../../common/Button';
import breakIcon from '../../assets/icons/icon-break.svg';
import clearResponsesIcon from '../../assets/icons/icon-clear-audience-reactions.svg';
import SocketClient from '../../utils/socket-client';
import { post, get, put } from '../../services/api';

import CustomDateTimeInput from './CustomDateTimeInput';
import WallMetadataModal from './WallMetadataModal';

class Tools extends Component {
  static propTypes = {
    activeEvent: PropTypes.shape({
      _id: PropTypes.string,
    }).isRequired,
    activeStudio: PropTypes.shape({
      _id: PropTypes.string,
    }).isRequired,
    updateEventSuccess: PropTypes.func.isRequired,
    hideEventSupportUsersValue: PropTypes.bool.isRequired,
    onToggleEventSupportUsers: PropTypes.func.isRequired,
  };

  state = {
    sendFeedToStudioQueue: false,
    enablePPTControl: false,
    startTime: new Date(),
    breakStarted: false,
    breakStartTime: null,
    enableShowWall: false,
    enableShareScreen: false,
    rehearsalMode: true,
  };

  componentDidMount() {
    const { activeEvent, activeStudio } = this.props;
    if (activeEvent && activeStudio) {
      this._checkPPTRemoteControl();
      this._setToolsState();
    }
  }

  componentDidUpdate(prevProps) {
    const { activeEvent } = this.props;
    if (prevProps.activeEvent !== activeEvent) {
      this._setToolsState();
    }
  }

  componentWillUnmount() {
    this._unmounted = true;
    clearInterval(this._timer);
    clearInterval(this._timerId);
    SocketClient.removeAllListeners();
  }

  _checkPPTRemoteControl = () => {
    this._timerId = setInterval(async () => {
      try {
        const { activeEvent } = this.props;
        const response = await get(`/event/${activeEvent._id}`);
        const { isPPTControllerRunning } = response.data;
        this.setState({ remoteControlConnected: isPPTControllerRunning });
      } catch (error) {
        console.error(error);
      }
    }, 3000);
  };

  _setToolsState = async () => {
    try {
      const { activeEvent } = this.props;
      const { data } = await get(`/event/${activeEvent._id}`);
      const { name, sendFeedToStudioQueue, enablePPTControl, breakStarted, breakStartTime, startTime, enableShowWall, enableShareScreen, rehearsalMode } = data;
      this._showName = name;
      if (breakStarted === true && breakStartTime) {
        clearInterval(this._timer);
        this._timer = setInterval(this._startBreakTimer, 1000);
      } else {
        clearInterval(this._timer);
        this.setState({
          timerStr: '',
        });
      }
      this.setState({ sendFeedToStudioQueue, enablePPTControl, breakStartTime, breakStarted, startTime, enableShowWall, enableShareScreen, rehearsalMode });
    } catch (error) {
      console.error(error);
    }
  };

  _updateEvent = async (data) => {
    const { activeEvent, updateEventSuccess } = this.props;
    try {
      const response = await put(`/event/${activeEvent._id}`, data);
      if (response.status === 200) {
        updateEventSuccess(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  _toggleSendFeedToStudioQueue = () => {
    this.setState({ sendFeedToStudioQueue: !this.state.sendFeedToStudioQueue }, async () => {
      const { sendFeedToStudioQueue } = this.state;
      await this._updateEvent({ sendFeedToStudioQueue });
      SocketClient.emitAdminAppSync({
        type: 'sendFeedToStudioQueue',
        payload: { value: sendFeedToStudioQueue },
      });
    });
  };

  _toggleEnablePPTControl = () => {
    this.setState({ enablePPTControl: !this.state.enablePPTControl }, async () => {
      const { enablePPTControl } = this.state;
      await this._updateEvent({
        enablePPTControl,
        v1APIMessages: [{ type: 'enablePPTControl', value: enablePPTControl, showName: this._showName }],
      });
      SocketClient.emitAdminAppSync({
        type: 'enablePPTControl',
        payload: { value: enablePPTControl },
      });
    });
  };

  _setMeetingStartTime = (date) => {
    this.setState({ startTime: date }, async () => {
      const { startTime } = this.state;
      if (startTime) {
        const value = Math.round(startTime.getTime() / 1000);
        await this._updateEvent({ startTime, v1APIMessages: [{ type: 'setMeetingStartTime', value, showName: this._showName }] });
        SocketClient.emitAdminAppSync({
          type: 'setMeetingStartTime',
          payload: { value: startTime },
        });
      }
    });
  };

  _onStartBreak = async () => {
    const confirm = await swal({
      title: 'Are you sure?',
      text: 'Are you sure you want to start a break? This will cause all participant feeds to pause.',
      buttons: {
        cancel: 'No',
        confirm: 'Yes',
      },
      dangerMode: true,
    });
    if (confirm) {
      this.setState({ breakStarted: true, breakStartTime: new Date() }, async () => {
        const { breakStartTime } = this.state;
        await this._updateEvent({
          breakStartTime,
          breakStarted: true,
          v1APIMessages: [{ type: 'break', value: 'start', showName: this._showName }],
        });
        clearInterval(this._timer);
        this._timer = setInterval(this._startBreakTimer, 1000);
        SocketClient.emitAdminAppSync({
          type: 'startMeetingBreak',
          payload: { value: breakStartTime },
        });
      });
    }
  };

  _onEndBreak = () => {
    this.setState({ breakStarted: false, breakStartTime: null }, async () => {
      await this._updateEvent({ breakStarted: false, breakStartTime: null, v1APIMessages: [{ type: 'break', value: 'stop', showName: this._showName }] });
      clearInterval(this._timer);
      this.setState({
        timerStr: '',
      });
      SocketClient.emitAdminAppSync({
        type: 'stopMeetingBreak',
      });
    });
  };

  _onClearAudienceReactions = async () => {
    try {
      const { activeEvent } = this.props;
      await post('/eventParticipant/clearAllResponses', { event: activeEvent._id });
      await this._updateEvent({ v1APIMessages: [{ type: 'clearAllAudienceResponses', showName: this._showName }] });
    } catch (err) {
      console.error(err);
    }
  };

  _startBreakTimer = () => {
    if (!this._unmounted) {
      const { breakStartTime } = this.state;
      const now = new Date().getTime();
      const start = new Date(breakStartTime).getTime();
      let diff = Math.round((now - start) / 1000);
      const h = Math.floor(diff / (60 * 60));
      diff -= h * 60 * 60;
      const m = Math.floor(diff / 60);
      diff -= m * 60;
      const s = diff;
      this.setState({
        timerStr: `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`,
      });
    }
  };

  _toggleEnableShowWall = () => {
    this.setState({ enableShowWall: !this.state.enableShowWall }, async () => {
      const { enableShowWall } = this.state;
      await this._updateEvent({
        enableShowWall,
      });
      SocketClient.emitAdminAppSync({
        type: 'enableShowWall',
        payload: { value: enableShowWall },
      });
    });
  };

  _toggleEnableShareScreen = () => {
    this.setState({ enableShareScreen: !this.state.enableShareScreen }, async () => {
      const { enableShareScreen } = this.state;
      await this._updateEvent({
        enableShareScreen,
      });
      SocketClient.emitAdminAppSync({
        type: 'enableShareScreen',
        payload: { value: enableShareScreen },
      });
    });
  };

  _toggleRehearsalMode = () => {
    this.setState({ rehearsalMode: !this.state.rehearsalMode }, async () => {
      const { rehearsalMode } = this.state;
      await this._updateEvent({
        rehearsalMode,
      });
      SocketClient.emitAdminAppSync({
        type: 'rehearsalMode',
        payload: { value: rehearsalMode },
      });
    });
  };

  _configMetadata = async () => {
    const { activeEvent } = this.props;
    await swal({
      buttons: {},
      className: 'swal-wide-content',
      closeOnClickOutside: true,
      closeOnEsc: false,
      content: (
        <WallMetadataModal
          activeEvent={activeEvent}
          onClose={() => {
            swal.close();
          }}
          onSubmit={async (selectedMetadataFields) => {
            await this._updateEvent({ wallMetadata: selectedMetadataFields });
            SocketClient.emit('wall-metadata', selectedMetadataFields);
            swal.close();
          }}
        />
      ),
    });
  };

  render() {
    const {
      sendFeedToStudioQueue,
      enablePPTControl,
      startTime,
      breakStarted,
      breakStartTime,
      timerStr,
      enableShowWall,
      enableShareScreen,
      rehearsalMode,
      remoteControlConnected,
    } = this.state;
    const { hideEventSupportUsersValue, onToggleEventSupportUsers, activeEvent } = this.props;
    const switchWidth = 34;
    const switchHeight = 16;
    const buttonStyle = {
      margin: 0,
      height: 32,
      padding: '2px 12px',
      fontSize: 12,
      fontWeight: 300,
      width: '100%',
    };

    return (
      <div className='tools-container'>
        <Switch
          label='Rehearsal Mode'
          onChange={this._toggleRehearsalMode}
          checked={rehearsalMode}
          height={switchHeight}
          width={switchWidth}
          containerStyle={{ marginBottom: 12 }}
          disabled={!rehearsalMode}
        />
        <Switch
          label='Send Program to Studio Queue'
          onChange={this._toggleSendFeedToStudioQueue}
          checked={sendFeedToStudioQueue}
          height={switchHeight}
          width={switchWidth}
          containerStyle={{ marginBottom: 12 }}
        />
        <Switch
          label='Enable PPT Remote Control'
          onChange={this._toggleEnablePPTControl}
          checked={enablePPTControl}
          height={switchHeight}
          width={switchWidth}
          containerStyle={{ marginBottom: 12 }}
          disabled={!remoteControlConnected}
        />
        <Switch
          label='Enable Show Wall Button'
          onChange={this._toggleEnableShowWall}
          checked={enableShowWall}
          height={switchHeight}
          width={switchWidth}
          containerStyle={{ marginBottom: 12 }}
        />
        <Switch
          label='Enable Share Screen Button'
          onChange={this._toggleEnableShareScreen}
          checked={enableShareScreen}
          height={switchHeight}
          width={switchWidth}
          containerStyle={{ marginBottom: 12 }}
        />
        <Switch
          label='Hide Event Support Users'
          onChange={onToggleEventSupportUsers}
          checked={hideEventSupportUsersValue}
          height={switchHeight}
          width={switchWidth}
        />
        <div style={{ display: 'flex', marginBottom: 12 }}>
          <CustomDateTimeInput value={startTime} customStyle={buttonStyle} onChange={this._setMeetingStartTime} activeEvent={activeEvent} />
        </div>
        <div className='custom-button-label' style={{ marginBottom: 12 }}>
          <Button
            type={breakStarted ? 'danger' : 'secondary'}
            text={breakStarted ? 'End Break' : 'Start Break'}
            onClick={(event) => {
              if (breakStarted) {
                this._onEndBreak(event);
              } else {
                this._onStartBreak(event);
              }
            }}
            containerStyle={buttonStyle}
            align='left'
            icon={breakIcon}
            iconStyle={{ width: 20, height: 20 }}
          />
          {breakStarted && breakStartTime && <div className='timer-label'>{`${timerStr || '00:00:00'}`}</div>}
        </div>
        <div style={{ display: 'flex', marginBottom: 12 }}>
          <Button type='secondary' text='Wall Metadata' onClick={this._configMetadata} containerStyle={buttonStyle} />
        </div>
        <Button
          type='danger-alt'
          text='Clear Audience Reactions'
          onClick={this._onClearAudienceReactions}
          containerStyle={buttonStyle}
          icon={clearResponsesIcon}
          iconStyle={{ width: 20, height: 20 }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeStudio: state.studio.active,
  activeEvent: state.events.active,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateEventSuccess: updateEventSuccessAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
