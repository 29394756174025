import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Button from '../../common/Button';
import Select from '../../common/Select';
import {get, post, put} from '../../services/api';
import List from '../../common/List';
import CheckboxListItem from './CheckboxListItem';
import swal from "@sweetalert/with-react";

class CopyPollsForm extends PureComponent {
  static propTypes = {
    activeEventId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    eventList: null,
    selectedEvent: null,
    pollList: null,
    selectedPolls: [],
    pollsAvailable: true,
  };

  componentDidMount() {
    const { activeEventId } = this.props;
    (async () => {
      try {
        let { data } = await get('/event');
        data = data.filter(obj => { return obj.isArchived !== true && obj._id !== activeEventId; });
        this.setState({ eventList: data });
      } catch (err) {
        console.error(err);
      }
    })();
  }

  _selectEvent = async ({ name }) => {
    const { eventList } = this.state;
    const currentSelection = eventList.find((evt) => evt.name === name);
    this.setState({ selectedEvent: name });

    try {
      const polls = await get(`/poll/list?event=${currentSelection._id}`);
      if (polls.data.length > 0) {
        this.setState({ pollList: polls.data, pollsAvailable: true });
      } else {
        this.setState({ pollsAvailable: false });
      }
    } catch (err) {
      console.error(err);
    }
  };

  _onSelect = (item, value) => {
    let { selectedPolls } = this.state;

    if (value === true) {
      selectedPolls.push(item);
    } else {
      selectedPolls = selectedPolls.filter(obj => obj._id !== item._id);
    }
    this.setState({ selectedPolls });
  }

  _onSubmitHandler = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { activeEventId, currentPolls, onSubmit } = this.props;
    const { selectedPolls } = this.state;

    for (let i = 0; i < selectedPolls.length; i++) {
      const poll = selectedPolls[i];
      try {
        // Make sure that there is not already a poll with the existing name
        let foundName = false;
        for (let n = 0; n < currentPolls.length; n++) {
          if (currentPolls[n].name === selectedPolls[i].name) {
            foundName = true;
          }
        }
        if (foundName === false) {
          const response = await post('/poll', { event: activeEventId, name: poll.name });
          const payload = {
            event: activeEventId,
            name: poll.name,
            data: poll.data,
            type: poll.type,
            inputType: poll.inputType,
            prefixAnswersWith: poll.prefixAnswersWith,
            displayResultLabelsAs: poll.displayResultLabelsAs,
            backgroundColor: poll.backgroundColor,
            correctAnswerIndex: poll.correctAnswerIndex || null,
            exclusiveAnswers: poll.exclusiveAnswers || [],
            minAnswers: poll.minAnswers || null,
            maxAnswers: poll.maxAnswers || null,
            sortAnswersBySelectionTime: poll.sortAnswersBySelectionTime || false,
            textColor: poll.textColor,
            spreadsheetDelimiter: poll.spreadsheetDelimiter,
          };
          if (poll.backgroundImage) {
            payload.backgroundImage = poll.backgroundImage;
          }
          await put(`/poll/${response.data._id}`, payload);

          if (i === selectedPolls.length - 1) {
            onSubmit(selectedPolls);
          }
        } else {
          await swal({
            title: 'Error',
            text: 'A poll with the name \"' + selectedPolls[i].name + '\" already exists for this event',
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
    if (selectedPolls.length === 0) {
      onSubmit(selectedPolls);
    }
  }

  render() {
    const { eventList, selectedEvent, pollList, pollsAvailable } = this.state;
    return (
      <div className='modal-form'>
        <div className='modal-form-header'>
          <h3>Copy Polls from Event</h3>
        </div>
        <form autoComplete='off' noValidate onSubmit={this._onSubmitHandler}>
          <div className='modal-form-content'>
            <div className='form-group'>
              <div className='form-label' style={{ flex: 1 }}>
                Event
              </div>
              <div className='form-control' style={{ flex: 1 }}>
                <Select
                  list={eventList}
                  listKey='_id'
                  listLabel='name'
                  onChange={this._selectEvent}
                  selected={selectedEvent}
                  currentOption={selectedEvent}
                  disabled={false}
                  containerStyle={{ width: 400 }}
                  small
                />
              </div>
            </div>
            { pollsAvailable && (
              <div className='form-group' style={{ minHeight: 300, height: '100%' }}>
                <div className='form-control' style={{ minHeight: 300, height: '100%', marginTop: 30, flex: 1 }}>
                  <List
                    data={pollList}
                    renderItem={(item) => <CheckboxListItem item={item} pollName={item.name} onChange={this._onSelect} />}
                    keyExtractor={(item) => `${item._id}`}
                    containerStyle={{ minHeight: 300, position: 'relative' }}
                  />
                </div>
              </div>
            )}
            { !pollsAvailable && (
              <div className='form-group' style={{ height: 300 }}>
                <div className='form-control' style={{ height: 300, marginTop: 30, flex: 1 }}>
                  <h4 style={{ color: '#a9aaab' }}>There are no polls configured for the selected event</h4>
                </div>
              </div>
            )}
          </div>
          <div className='modal-form-footer'>
            <Button type='primary' submit text='Copy' onClick={this._onSubmitHandler} />
          </div>
        </form>
      </div>
    );
  }
}

export default CopyPollsForm;
